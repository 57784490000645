import { lazy, Suspense } from "react";
import { Routes, Route, useNavigate, Link, useLocation } from "react-router-dom";
import { useEffect } from "react";
import { useAuth } from "AuthContext";
import Header from "../components/Header";
import routes from "./config";
import { Styles } from "../styles/styles";

const Protected = (props) => {
    const navigate = useNavigate();
    const location = useLocation();
    const { login, demo } = useAuth();
    if (!demo && !login && location.pathname !== '/')
      navigate({ pathname: "/" })
    else
      return props.children;
};

const Router = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const { login, demo } = useAuth();



  useEffect(() => {
     if (!demo && !login && ["pricing", "verifyEmail"].includes(location.pathname)) navigate({ pathname: "/" });
  })

  return (
    <Suspense fallback={null}>
      <Styles />
      <Header />
      <Routes>
        {routes.map((routeItem) => {
            const Component = lazy(() => import(`../pages/${routeItem.element}`))
            return (
              <Route
                key={routeItem.element}
                path={routeItem.path}
                exact={routeItem.exact}
                element={<Component/>}
              >
                
              </Route>
            );
        })}
      </Routes>
    </Suspense>
  );
};

export default Router;
