import React, {useState, useEffect} from 'react';
import { useNavigate  } from 'react-router-dom';

import {Modal} from "../Modal/Modal"
import { AuthAPI } from 'api/auth.js';
import { useAuth } from "AuthContext"

import './waitlist.css'

const CustomForm = ({modalOpenState, ...props}) => {

    const navigate = useNavigate()
    const {setLogin} = useAuth();
    const [modalOpen, setModalOpen] = modalOpenState;
    const [status, setStatus] = useState("idle");
    const [message, setMessage] = useState("");

    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('');

    const handleSubmit = (e) => {
        setStatus("sending")
        e.preventDefault();
        const validation = (email && password && email.indexOf("@") > -1) === true
        if (validation) {
            const authAPI = new AuthAPI()
            authAPI.register({email, password}).then(
                async (response) => {
                    response = await response.json()  
                    if (response.status === "success") {
                        setStatus("success");
                        setMessage("Redirecting...");
                        setModalOpen(false);
                        setLogin(true)
                        navigate({pathname:`/boards`})
                    } else {
                        setStatus("error");
                        setMessage(response.message);
                        console.log(response.error)
                    }  
                }
            ).catch(
                (error) => {
                    setStatus("error");
                    setMessage(error.message);
                    console.log(error);
                }
            )
        }
    }

    

    useEffect(() => {
        if(status === "success") clearFields();
        if(!modalOpen || status === "success") clearFields();
    }, [status, modalOpen])

    const clearFields = () => {
        setEmail('');
        setPassword('');
        setStatus("idle");
        setMessage("");
    }
    
    return (
        <form
            className="mc__form"
            onSubmit={(e) => handleSubmit(e)}
        >
            <h3 className="mc__title">
                {status === "success" ? "Thanks for Joining!" : "Register/Login"}
            </h3>

            {status === "sending" && (
                <div
                    className="mc__alert mc__alert--sending"
                >sending...</div>
            )}
            {status === "error" && (
                <div className="mc__alert mc__alert--error">{message}</div>
            )}
            {status !== "success" ? (
                <div className="mc__field-container">


                    <input
                        label="Email"
                        onChange={(e) => setEmail(e.target.value)}
                        type="email"
                        value={email}
                        placeholder="your@email.com"
                        required
                        name="email"
                    />
                    <input
                        label="Password"
                        onChange={(e) => setPassword(e.target.value)}
                        type="password"
                        value={password}
                        placeholder="******"
                        required
                        name="password"
                    />
            {/*Close button appears if form was successfully sent*/}
            {
                status === 'success' ? <button
                    handleClick={() => setModalOpen(false)}
                    label="close"
                    size="big"
                    customClass="g__justify-self-center"
                /> : <input
                    label="Login"
                    type="submit"
                    value="Submit"
                />

            }

                </div>
            ) : null}

            
        </form>
    );
};



export const MailchimpForm = props => {

    return (
        <Modal modalOpenState={props.modalOpenState}>
            <div className="mc__form-container" >
                <CustomForm modalOpenState={props.modalOpenState}></CustomForm>
            </div>
        </Modal>

    )
}