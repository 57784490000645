import React from "react";
import { createRoot } from 'react-dom/client';
import "./index.css";
import HomePage from "./homepage";
import { AuthProvider } from 'AuthContext'

const container = document.getElementById('root');
const root = createRoot(container!); // createRoot(container!) if you use TypeScript
root.render(<React.StrictMode>
  <AuthProvider>
  <HomePage />
  </AuthProvider>
</React.StrictMode>);

// require('dotenv').config();