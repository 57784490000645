import { HashRouter } from "react-router-dom";
import { I18nextProvider } from "react-i18next";
import "antd/dist/antd.min.css";

import Router from "./router";
import i18n from "./translation";

const HomePage = () => {
 
  return (
    <HashRouter>
      <I18nextProvider i18n={i18n}>
        <Router />
      </I18nextProvider>
    </HashRouter>
    )
};

export default HomePage;
