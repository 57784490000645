import React from 'react';
import './Modal.css'

export const Modal = (props) => {
    const [modalOpen, setModalOpen] = props.modalOpenState;

    let title = ""
    if (props.title){
        title = (
            <header className="modalHeader">
                        <p className="modalTitle">{props.title}</p>
                        <button className="closeModal" aria-label="close" onClick={() => setModalOpen(false)}></button>
            </header>
        )
    }

    return (
        <div className={`modal ${modalOpen ? "display-block" : "display-none"}`}>
            <div className="modalBackground" onClick={() => {setModalOpen(false)}}></div>
            <div className="modalCard">
                {title}
                <section className="modalBody">
                    {props.children}
                </section>
            </div>
        </div>
    );
}