import fetchAPI from "./utils";

// integrate login API
const BASE_URL = `${process.env.REACT_APP_API_URL}/auth`;

class AuthAPI {
  async register(user) {
    const url = `${BASE_URL}/registerLogin`;
    const response = await fetchAPI(url, "POST", user);
    return response;
  }

  async login(user) {
    const url = `${BASE_URL}/login`;
    const response = await fetchAPI(url, "POST", user);
    return response;
  }

  async auth() {
    const url = `${BASE_URL}/authenticate`;
    const response = await fetchAPI(url, "GET");
    return response;
  }

  async logout() {
    const url = `${BASE_URL}/logout`;
    const response = await fetchAPI(url, "GET");
    return response;
  }

  async verifyEmail(token) {
    const url = `${BASE_URL}/verifyEmail`;
    const response = await fetchAPI(url, "POST", { token });
    return response;
  }
}

class User {
  async deleteUser(id) {
    const url = `${BASE_URL}/user/${id}`;
    const response = await fetchAPI(url, "DELETE");
    return response;
  }

  async getUser(id) {
    const url = `${BASE_URL}/user/${id}`;
    const response = await fetchAPI(url, "GET");
    return response;
  }
}

export { AuthAPI, User };
