const routes = [
  {
    path: "/",
    exact: true,
    element: "Home",
  },
  {
    path: "/verifyEmail",
    exact: true,
    element: "VerifyEmail",
  },
  {
    path: "/pricing",
    exact: true,
    element: "Pricing",
  },
  {
    path: "/boards",
    exact: true,
    element: "ListBoards",
    login: false
  },
  {
    path: "/boards/:id",
    exact: true,
    element: "100Ideas",
    login: false
  },
];

export default routes;
