import {ReactNode, FC, createContext, useContext, useState, useEffect } from 'react'
import { useNavigate } from 'react-router-dom'
import { AuthAPI } from './api/auth.js' 

export const AuthContext = createContext<any>(null)

export const useAuth = () => useContext<any>(AuthContext)

export const AuthProvider: FC<ReactNode> = ({children}) => {
  const [token, setToken] = useState<any>(null)
  const [login, setLogin] = useState<any>(JSON.parse(localStorage.getItem('login') || 'false'))
  const [demo, setDemo] = useState<any>(false)

  useEffect(() => {
    localStorage.setItem('login', login)
    if (login === false){
      setToken(null)
      new AuthAPI().logout()
    }
  }, [login])

  return (
    <AuthContext.Provider value={{token, setToken, login, setLogin, demo, setDemo}}>
        {children}
    </AuthContext.Provider>
  )
}