import { useState } from "react";
import { Row, Col, Drawer } from "antd";
import { withTranslation } from "react-i18next";
import Container from "../../common/Container";
import { SvgIcon } from "../../common/SvgIcon";
import { Button } from "../../common/Button";
import { useNavigate } from 'react-router-dom';
import { MailchimpForm } from '../../../app/components/waitlist/waitlist'
import {useAuth} from 'AuthContext'

import {
  HeaderSection,
  LogoContainer,
  Burger,
  NotHidden,
  Menu,
  CustomNavLinkSmall,
  Label,
  Outline,
  Span,
} from "./styles";

const Header = ({ t }: any) => {
  const navigate = useNavigate();
  const [visible, setVisibility] = useState(false);
  const [modalOpen, setModalOpen] = useState(false);
  const {login, setLogin} = useAuth();

  const showDrawer = () => {
    setVisibility(!visible);
  };

  const onClose = () => {
    setVisibility(!visible);
  };

  const logout = () => {
    setLogin(false)
    navigate("/")
  };

  const MenuItem = () => {
    const scrollTo = (id: string) => {
      const element = document.getElementById(id) as HTMLDivElement;
      element.scrollIntoView({
        behavior: "smooth",
      });
      setVisibility(false);
    };

    const pricing = login ? "" : <CustomNavLinkSmall onClick={() => navigate("/pricing")}><Span>{t("Pricing")}</Span></CustomNavLinkSmall>
    const boards = login ? <CustomNavLinkSmall onClick={() => navigate("/boards")}><Span>{t("Board")}</Span></CustomNavLinkSmall> : ""
    
    return (
      <>
        <CustomNavLinkSmall onClick={() => navigate("/")}>
          <Span>{t("Home")}</Span>
        </CustomNavLinkSmall>
        {pricing}
        {boards}
        <CustomNavLinkSmall
          id="register"
          style={{ width: "180px" }}
          onClick={() => {login? logout() : setModalOpen(!modalOpen)}}
        >
          <Span>
            <Button>{login? t('Logout') : t("Register/Login")}</Button>
          </Span>
        </CustomNavLinkSmall>
      </>
    );
  };

  return (
    <HeaderSection>
      <Container>
        <Row justify="space-between">
          <LogoContainer to="/" aria-label="homepage">
            <SvgIcon src="logo.svg" width="101px" height="64px" />
          </LogoContainer>
          <NotHidden>
            <MenuItem />
          </NotHidden>
          <Burger onClick={showDrawer}>
            <Outline />
          </Burger>
        </Row>
        <Drawer closable={false} visible={visible} onClose={onClose}>
          <Col style={{ marginBottom: "2.5rem" }}>
            <Label onClick={onClose}>
              <Col span={12}>
                <Menu>Menu</Menu>
              </Col>
              <Col span={12}>
                <Outline />
              </Col>
            </Label>
          </Col>
          <MenuItem />
        </Drawer>
      </Container>
      <MailchimpForm modalOpenState={[modalOpen, setModalOpen]}></MailchimpForm>
    </HeaderSection>
  );
};

export default withTranslation()(Header);
