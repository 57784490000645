let login = true

const alertLogin = () => {
  alert('Token expired! Please login again');
  localStorage.setItem('login', false);
  window.location = "/"
}

const fetchAPI = (
  url,
  method,
  body,
  headers
) => {
  headers = headers || {};
  headers = {
    ...headers,
    "Content-Type": "application/json",
    Accept: "application/json"
  };
  const controller = new AbortController()
  const timeoutId = setTimeout(() => controller.abort(), 5000)
  const options = {
    method,
    headers,
    body: JSON.stringify(body),
    credentials: "include",
    mode: "cors",
    // signal: controller.signal, #uncomment if you want to add timeout
  };

  if (!login){
    alertLogin()
  }
  
  return fetch(url, options).then(
    (response) => {
      if (response.status === 401){
        login = false;
        alertLogin()
      }
      else if (response.status >= 500){
        throw Error("Something went wrong! Please contact support")
      }
      else return new Promise((resolve, reject) => {
          resolve(response);
      });
    }
  ).catch(
    (error) => {
      console.log("errror name", error.name)
      if (error.name === "TypeError" || error.name === "AbortError") error.message = "Something went wrong! Please contact support"
      throw error;
    }
  )
};

export default fetchAPI;
